@import '../../../../scss/theme-bootstrap';

.gift__new-filter-layout {
  .sd-product-grid {
    .elc-product-grid--header {
      max-width: 1920px;
      margin: 8px 0 auto;
      @include breakpoint($medium-up) {
        margin: 38px 0 auto;
      }
      .elc-orderable-filters-wrapper {
        width: 100%;
        padding-bottom: 5px;
        text-align: center;
        @include breakpoint($medium-up) {
          padding-bottom: 34px;
          border-bottom: 1px solid $color-cream-stroke;
        }
        &.filters-applied {
          padding-bottom: 57px;
          @include breakpoint($medium-up) {
            padding-bottom: 15px;
          }
        }
      }
      .elc-orderable-filters-wrapper ~ div.elc-sort-wrapper {
        position: relative;
        display: block;
        text-align: $rdirection;
        #{$rdirection}: 0;
        #{$ldirection}: 0;
        border-top: 1px solid $color-cream-stroke;
        width: 100%;
        margin: 0 20px;
        @include breakpoint($medium-up) {
          width: auto;
          border: none;
          @include swap_direction(margin, 20px 0 20px auto);
        }
        .elc-dropdown-arrow-icon {
          width: 24px;
          height: 24px;
        }
        .elc-dropdown-readonly-input {
          @include body-text2;
        }
        .elc-dropdown-wrapper {
          padding: 0;
          .elc-dropdown-arrow {
            #{$rdirection}: -5px;
            #{$ldirection}: auto;
          }
          .elc-dropdown-readonly-input {
            padding: 0;
            text-transform: capitalize;
            letter-spacing: -0.1px;
            justify-content: flex-end;
          }
        }
      }
    }
    .elc-product-grid--apply-filter {
      display: none;
    }
    .elc-product-grid--filters-section {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 0 20px;
      @include breakpoint($medium-up) {
        display: inline;
        overflow: none;
        white-space: normal;
        width: auto;
        margin: 0 50px;
        padding: 0;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      .elc-product-grid--selected-filter-values-wrapper {
        position: absolute;
        bottom: 71px;
        margin: 0;
        width: 100%;
        overflow: scroll;
        display: block;
        text-align: $ldirection;
        #{$ldirection}: 0;
        padding: 0 17px;
        font-family: $main-font-regular;
        @include breakpoint($medium-up) {
          position: relative;
          #{$ldirection}: 0;
          bottom: 0;
          padding: 0;
          margin: 0;
          text-align: $ldirection;
          overflow: none;
          white-space: normal;
          width: auto;
          display: inline;
        }
        &::-webkit-scrollbar {
          display: none;
        }
        &::before {
          content: '\A';
          white-space: pre-line;
          display: block;
          margin-top: 5px;
          height: 0;
          @include breakpoint($medium-up) {
            margin-top: 37px;
          }
        }
      }
      .elc-product-grid--filter-set-wrapper {
        padding: 0;
        display: inline;
        label {
          display: none;
        }
        .elc-product-grid--filter-options-wrapper {
          background: transparent;
          border: none;
          padding: 0;
          display: inline;
        }
      }
      .elc-product-grid--filter-option {
        border-radius: 20px;
        padding: 12px 24px;
        box-shadow: inset 0 0 0 1px $color-cream-stroke;
        height: 40px;
        line-height: 18px;
        font-size: 14px;
        font-family: $main-font;
        background: 0 0;
        text-transform: capitalize;
        color: $color-black;
        display: inline;
        letter-spacing: -0.1px;
        outline: none;
        margin: 0 20px 20px 0;
        @include breakpoint($medium-up) {
          line-height: 17px;
          font-size: 13px;
        }
        &:hover {
          text-decoration: none;
        }
        &:focus {
          outline: none !important;
        }
        &.button--dark {
          box-shadow: inset 0 0 0 1px $color-black;
        }
      }
      .elc-product-grid--clear-all,
      .elc-product-grid--selected-filter-value {
        padding: 0;
        text-transform: capitalize;
        height: auto;
        text-decoration: none;
        color: $color-black;
        font-family: $main-font;
        background: transparent;
        font-size: 14px;
        margin-#{$rdirection}: 28px;
        padding-#{$ldirection}: 19px;
        line-height: 20px;
        position: relative;
        letter-spacing: -0.1px;
        &:hover,
        :focus {
          border: none;
          outline: none;
          box-shadow: none;
        }
        @include breakpoint($medium-up) {
          float: $ldirection;
          font-size: 12px;
          margin-#{$rdirection}: 29px;
          letter-spacing: 0.001em;
          background: 0 0;
          font-weight: 400;
          padding-#{$ldirection}: 20px;
          line-height: 20px;
          position: relative;
        }
        .elc-remove-icon {
          position: absolute;
          width: 15px;
          display: block;
          height: 15px;
          line-height: initial;
          #{$ldirection}: 0;
          margin: 0;
          top: 2px;
        }
      }
      .elc-product-grid--selected-filter-value {
        &:first-child {
          margin-#{$ldirection}: 100px;
          @include breakpoint($medium-up) {
            margin-#{$ldirection}: 0;
          }
        }
      }
      .elc-product-grid--clear-all {
        border: none;
        margin: 0 0 6px;
        width: auto;
        position: absolute;
        bottom: 71px;
        #{$ldirection}: 0;
        z-index: 1;
        background: $color-cream;
        @include swap_direction(padding, 0 10px 0 42px);
        @include breakpoint($medium-up) {
          position: relative;
          #{$ldirection}: auto;
          bottom: auto;
          background: transparent;
          @include swap_direction(padding, 0 0 0 19px);
        }
        .elc-remove-icon {
          #{$ldirection}: 22px;
          @include breakpoint($medium-up) {
            #{$ldirection}: 0;
          }
        }
      }
    }
  }
  .bespoke-gifting__header {
    @include breakpoint($medium-up) {
      padding-bottom: 20px;
    }
  }
}
